import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide37/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide37/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide37/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide37/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide37/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      8 tips para mejorar tu servicio al cliente por WhatsApp
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      WhatsApp cuenta con una asombrosa base de 2 mil millones de usuarios
      activos a nivel mundial, consolidando su posición como la plataforma de
      mensajería instantánea más popular. Su accesibilidad inmediata, personal y
      global la convierte en una herramienta invaluable.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Aprovechando WhatsApp, las empresas pueden abordar una variedad de tareas,
    desde la atención de consultas de los clientes hasta la exhibición de
    productos, notificaciones y actualizaciones.
    <br />
    <br />
    En esencia, el envío de mensajes a través de WhatsApp capacita a las
    empresas para fortalecer su soporte al cliente e inaugurar una nueva era de
    comunicaciones empresariales interactivas y significativas.
  </p>
)

const data = {
  start: {
    support: "Guía | WhatsApp ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Añade WhatsApp a un ciclo omnicanal.",
      },
      {
        key: 2,
        text: "Gestionar reservas y citas.",
      },
      {
        key: 3,
        text: "Interacciones postventa.",
      },
      {
        key: 4,
        text: "Utiliza mensajes multimedia.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
